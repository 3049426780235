@font-face {
  font-family: "Craft Gothic";
  src: url("../src/assets/fonts/CraftGothic-Light.eot");
  src: local("../src/assets/fonts/CraftGothic-Light"),
    url("../src/assets/fonts/CraftGothic-Light.eot?#iefix") format("embedded-opentype"),
    url("../src/assets/fonts/CraftGothic-Light.woff2") format("woff2"),
    url("../src/assets/fonts/CraftGothic-Light.woff") format("woff"),
    url("../src/assets/fonts/CraftGothic-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Craft Gothic";
  src: url("../src/assets/fonts/CraftGothic-Regular.eot");
  src: local("../src/assets/fonts/CraftGothic-Regular"),
    url("../src/assets/fonts/CraftGothic-Regular.eot?#iefix") format("embedded-opentype"),
    url("../src/assets/fonts/CraftGothic-Regular.woff2") format("woff2"),
    url("../src/assets/fonts/CraftGothic-Regular.woff") format("woff"),
    url("../src/assets/fonts/CraftGothic-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Craft Gothic";
  src: url("../src/assets/fonts/CraftGothic-DemiBold.eot");
  src: local("../src/assets/fonts/CraftGothic-DemiBold"),
    url("../src/assets/fonts/CraftGothic-DemiBold.eot?#iefix") format("embedded-opentype"),
    url("../src/assets/fonts/CraftGothic-DemiBold.woff2") format("woff2"),
    url("../src/assets/fonts/CraftGothic-DemiBold.woff") format("woff"),
    url("../src/assets/fonts/CraftGothic-DemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Craft Gothic";
  src: url("../src/assets/fonts/CraftGothic-Bold.eot");
  src: local("../src/assets/fonts/CraftGothic-Bold"),
    url("../src/assets/fonts/CraftGothic-Bold.eot?#iefix") format("embedded-opentype"),
    url("../src/assets/fonts/CraftGothic-Bold.woff2") format("woff2"),
    url("../src/assets/fonts/CraftGothic-Bold.woff") format("woff"),
    url("../src/assets/fonts/CraftGothic-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Craft Gothic";
  font-size: 18px;
  font-weight: 400;
  background-color: #ffffff;
  color: #314259;
  overflow-x: hidden;
}

a {
  color: #23b2f4;
}

a:hover {
  color: #23b2f4;
}

/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #23b2f4;
}

img.brand_logo {
  width: 112px;
}

.main_navbar {
  z-index: 99;
}

.navbar-sticky--moved-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgb(0, 21, 215);
  background: linear-gradient(90deg,
      rgba(0, 21, 215, 1) 15%,
      rgba(36, 179, 245, 1) 100%);
  margin-top: -100px;
  -webkit-box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
}

.navbar-sticky--on {
  margin-top: 0;
}

.navbar-sticky--transitioned {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.navbar_right {
  gap: 30px;
}

.navbar_right .primary_btn {
  min-width: 135px;
}

.navbar_right .primary_btn:after {
  background: rgba(0, 21, 214, 0.1);
}

.navbar_right .primary_btn:hover>.icon-right.after:before {
  left: -80px;
}

.navbar_middle {
  gap: 15px;
}

.navbar_middle li a {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.afterlogin_dropdown .user_name {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}

.afterlogin_dropdown .user_email {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}

.user_icon {
  width: 45px;
  height: 45px;
  background: #23b2f4;
  color: #fff;
  font-size: 32px;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
}

.afterlogin_dropdown a.nav-link {
  display: flex;
  align-items: center;
  gap: 10px;
}

.afterlogin_dropdown a.nav-link>div {
  display: flex;
  flex-direction: column;
}

.afterlogin_dropdown .dropdown-toggle::after {
  background: url("../src/assets/images/menu_dropdown.png") no-repeat;
  width: 23px;
  height: 24px;
  border: none;
}

.afterlogin_dropdown .dropdown-menu {
  padding: 0;
}

.afterlogin_dropdown .dropdown-menu .dropdown-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px 10px;
  color: #314259;
}

.afterlogin_dropdown .dropdown-menu .dropdown-item:hover {
  background-color: rgb(35 178 244 / 15%);
}

.page_header {
  background: rgb(203, 239, 255);
  background: linear-gradient(90deg, #cbefff 0%, #0061ff 50%, #69d0ff 100%);
  position: relative;
  z-index: 1;
  color: #fff;
  margin-top: -90px;
  padding: 100px 0;
}

.page_header::before {
  content: "";
  background: url("../src/assets/images/banner_bg.png") no-repeat top;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.page_header::after {
  content: "";
  background: #0011ad;
  background: linear-gradient(90deg, #0011ad 15%, #0094ff 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
}

.page_header h1 {
  color: #fff;
  font-size: 40px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.8px;
  text-transform: capitalize;
  margin-bottom: 40px;
}

.page_header h1 span {
  font-weight: 700;
}

.page_header h5 {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  max-width: 80%;
  margin-bottom: 40px;
}

.banner_img {
  position: relative;
  z-index: 1;
}

/* .banner_img::before {
  content: "";
  background: url("../src/assets/images/banner_img_bg.png") no-repeat center;
  width: 800px;
  height: 752px;
  position: absolute;
  z-index: -1;
  -webkit-animation: rotating 10s linear infinite;
  -moz-animation: rotating 10s linear infinite;
  -ms-animation: rotating 10s linear infinite;
  -o-animation: rotating 10s linear infinite;
  animation: rotating 10s linear infinite;
} */

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.primary_btn {
  background: none;
  color: #fff;
  position: relative;
  outline: none;
  border: none;
  height: 45px;
  padding: 10px 35px;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  z-index: 2;
  transition: 0.01s 0.23s ease-out all;
  overflow: hidden;
  display: inline-block;
  text-decoration: none !important;
}

.primary_btn:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 35%;
  background: rgba(255, 255, 255, 0.15);
  z-index: -1;
  transition: 0.3s ease-in all;
}

.primary_btn:after {
  content: "";
  position: absolute;
  left: -5%;
  top: 0;
  height: 100%;
  width: 5%;
  background: rgba(37, 179, 244, 0.3);
  transition: 0.4s 0.02s ease-in all;
  z-index: -1;
}

.primary_btn:hover {
  cursor: pointer;
  color: transparent;
}

.primary_btn:hover:before {
  left: 120%;
  width: 25%;
}

.primary_btn:hover:after {
  left: 0;
  width: 100%;
}

/* .primary_btn:hover > .icon-right.after:after {
  left: -80px;
  color: white;
  transition: 0.2s 0.2s ease all;
} */
.primary_btn:hover>.icon-right.after:before {
  left: -100px;
  top: 15px;
  opacity: 0.8;
  color: #fff;
}

.icon-right {
  position: absolute;
  top: -4px;
  right: 0px;
}

.icon-right:after {
  font-family: "FontAwesome";
  content: "→";
  font-size: 24px;
  display: inline-block;
  position: relative;
  top: 26px;
  transform: translate3D(0, -55%, 0);
}

.icon-right.after:after {
  left: -250px;
  color: black;
  transition: 0.15s 0.25s ease left, 0.5s 0.05s ease color;
}

.icon-right.after:before {
  content: "Click Me!";
  position: absolute;
  left: -230px;
  top: 14px;
  opacity: 0;
  transition: 0.2s ease-in all;
}

.section {
  padding: 50px 0;
}

.participant_panel {
  display: flex;
  padding: 40px 145px;
  justify-content: space-between;
  align-items: center;
  gap: 145px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.1);
  margin-top: -40px;
  z-index: 10;
  position: relative;
}

.participant_panel_left div,
.participant_panel_right div {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 30px;
}

.participant_panel p {
  font-size: 24px;
  line-height: normal;
  letter-spacing: -0.48px;
  margin-bottom: 0;
}

.participant_panel h2 {
  color: #23b2f4;
  font-size: 96px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -1.92px;
  margin-bottom: 0;
}

.participant_panel_left {
  position: relative;
}

.participant_panel_left::after {
  content: "";
  background: rgba(49, 66, 89, 0.3);
  width: 1px;
  height: 156px;
  position: absolute;
  top: 20px;
  right: -140px;
}

.inner_bg_sec {
  border-radius: 10px;
  padding: 40px 70px 50px 70px;
  background: #d3f1ff;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.why_invest_sec {
  background: #d3f1ff url("../src/assets/images/bg_01.png") no-repeat center;
}

.why_invest_box div {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
}

.why_invest_box {
  background: #fff;
  padding: 50px;
  height: 100%;
  border-radius: 5px;
}

.why_invest_box h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.48px;
  margin-bottom: 0;
}

.why_invest_sec .row:nth-child(2) [class*="col-"] {
  margin-bottom: 30px;
}

.para_tag {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

.main_title {
  font-size: 40px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.8px;
  margin-bottom: 40px;
}

.custom_accordian .accordion-item {
  border: none;
}

.custom_accordian .accordion-button {
  background: transparent;
  box-shadow: none;
  color: #314259;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.48px;
}

.custom_accordian .accordion-item.active-accordion {
  background: #f2fbff;
}

.custom_accordian .accordion-button:not(.collapsed) {
  color: #23b2f4;
}

.custom_accordian .accordion-body {
  padding-top: 0;
}

.roi_calc_sec {
  padding: 80px 100px;
}

.roi_calc_sec::before {
  content: "";
  background: url("../src/assets/images/bg_02.png") no-repeat left center;
  width: 923px;
  height: 1038px;
  position: absolute;
  left: -35%;
  top: 0;
  z-index: -1;
}

.roi_calc_box {
  border-radius: 5px;
  background: #fff;
  padding: 50px;
  height: 100%;
}

.roi_calc_box.roi_chart_box .donut_chart {
  display: flex;
  align-items: center;
  justify-content: center;
}

.roi_calc_sec [class*="col-"] {
  margin-bottom: 30px;
}

.rc-slider-handle {
  box-shadow: 0 0 0 1px #23b2f4 !important;
  opacity: 1 !important;
}

.roi_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.roi_flex label {
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.36px;
  white-space: nowrap;
}

.roi_flex .input-group {
  width: 40%;
  height: 54px;
}

.roi_flex input {
  border: 1px solid rgba(181, 232, 255, 0.8) !important;
  border-right: 0 !important;
  box-shadow: none !important;
}

.roi_flex .input-group-text {
  background: transparent;
  border: 1px solid rgba(181, 232, 255, 0.8);
}

.roi_calc_box .form-group {
  margin-bottom: 60px;
}

.roi_calc_box .form-group:last-child {
  margin-bottom: 0;
}

.roi_flex select.form-select {
  border: 1px solid rgba(181, 232, 255, 0.8) !important;
  box-shadow: none !important;
  width: 40%;
  height: 54px;
}

.roi_calc_value {
  max-width: 70%;
  margin: 0 auto;
}

.roi_calc_value>div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.roi_calc_value>div:last-child {
  margin-bottom: 0;
}

.roi_calc_value>div label {
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.36px;
}

.roi_calc_value>div label:nth-child(2) {
  color: #23b2f4;
  font-size: 32px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.64px;
}

.slider_box {
  display: flex;
  padding: 20px;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 25px 0px rgba(198, 237, 255, 0.5);
  margin: 30px;
}

.slider_box img {
  width: 50px;
  height: 50px;
}

.slider_box span {
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.48px;
}

.custom_slider .slick-next:before,
.custom_slider .slick-prev:before {
  display: none;
}

.custom_slider .slick-next,
.custom_slider .slick-prev {
  width: 40px;
  height: 40px;
  z-index: 10;
}

.custom_slider .slick-next {
  background: url("../src/assets/images/next_icon.png") no-repeat !important;
}

.custom_slider .slick-prev {
  background: url("../src/assets/images/prev_icon.png") no-repeat !important;
}

.news_section .primary_btn,
.faq_section .primary_btn {
  color: #23b2f4;
  margin-top: 30px;
}

.news_section .primary_btn:hover,
.faq_section .primary_btn:hover {
  color: transparent;
}

.news_section .primary_btn:before {
  background: #fff;
}

.news_section .primary_btn:hover>.icon-right.after:before,
.faq_section .primary_btn:hover>.icon-right.after:before {
  color: #23b2f4;
  left: -88px;
}

.faq_section .primary_btn:before {
  background: rgb(36 178 245 / 30%);
}

.news_wrapper {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: 200px;
  grid-auto-flow: dense;
  border-radius: 10px;
  background: #fff;
  padding: 20px;
}

.news_wrapper .wide {
  grid-column: span 2;
}

.news_wrapper>a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.news_wrapper>a>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

footer.footer {
  border: 1px solid rgba(255, 255, 255, 0.75);
  background: rgba(242, 251, 255, 0.8);
  backdrop-filter: blur(4.5px);
  padding: 30px 0;
}

.footer_logo {
  width: 112px;
  margin-bottom: 20px;
}

.footer_panel_top,
.footer_panel_bottom {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.footer_panel_top {
  border-bottom: 1px solid #69d0ff;
  padding-bottom: 15px;
  margin-bottom: 12px;
}

.footer_panel_top_left {
  max-width: 40%;
}

.footer_txt {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 400;
  line-height: normal;
}

ul.footer_social_links {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0;
  margin: 15px 0 0;
}

ul.footer_social_links li a {
  background: #314259;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: #fff;
  font-size: 16px;
  transition: 0.2s all;
  text-decoration: none;
}

ul.footer_social_links li a:hover {
  background: #23b2f4;
}

.footer_menu_links {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 0;
  margin: 0;
}

.footer_menu_links li {
  position: relative;
}

.footer_menu_links li::after {
  content: "";
  background: #314259;
  width: 1px;
  height: 12px;
  position: absolute;
  right: -15px;
  top: 9px;
}

.footer_menu_links li:last-child::after {
  display: none;
}

.footer_menu_links li a {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: #314259;
  text-decoration: none;
}

.footer_menu_links li a:hover {
  color: #23b2f4;
}

.footer_panel_bottom_left p {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

.footer_panel_bottom_left p a {
  color: #314259;
  text-decoration: none;
}

/* Login and Register Pages Css */
.auth_wrapper {
  background: #0011ad;
  background: linear-gradient(90deg, #0011ad 15%, #0094ff 100%);
  color: #fff;
  position: relative;
  z-index: 1;
  margin-top: -90px;
  padding: 150px 0;
}

.auth_wrapper::before {
  content: "";
  background: url("../src/assets/images/banner_bg.png") no-repeat top;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.auth_box {
  border-radius: 10px;
  background: linear-gradient(100deg,
      rgba(0, 23, 215, 0.6) 9.14%,
      rgba(255, 255, 255, 0) 75.07%);
  padding: 50px 0 50px 50px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.primary_form .form-group {
  margin-bottom: 20px;
}

.primary_inp {
  border-radius: 5px;
  border: 1px solid rgba(122, 183, 244, 0.25) !important;
  background: rgba(0, 184, 129, 0.05) !important;
  backdrop-filter: blur(2.5px);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  box-shadow: none !important;
  color: #eee !important;
  height: 60px;
  padding: 12px 20px;
}

.primary_inp::placeholder {
  color: #5b91ef;
}

.primary_checkinput {
  border-radius: 2px;
  border: 1px solid rgba(122, 183, 244, 0.25) !important;
  background-color: rgba(0, 184, 129, 0.05);
  backdrop-filter: blur(2.5px);
  box-shadow: none !important;
}

.primary_form label {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 5px;
}

.secondary_btn {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 10px 30px;
  text-align: center;
  font-size: 16px;
  line-height: normal;
  text-decoration: none;
  color: #fff;
  font-weight: 400;
  background: transparent;
  transition: ease-out 0.5s;
  border: 2px solid #23b2f4;
  border-radius: 5px;
  box-shadow: inset 0 -100px 0 0 #23b2f4;
}

.secondary_btn:hover {
  color: white;
  box-shadow: inset 0 0 0 0 #23b2f4;
}

.secondary_btn:active {
  transform: scale(0.9);
}

.auth_box h2 {
  font-size: 40px;
  font-weight: 400;
  line-height: normal;
}

.auth_box h2 span {
  font-weight: 700;
}

.auth_box form.primary_form {
  max-width: 80%;
}

.d_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Custom btn */
.custom_btn {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 10px 30px;
  text-align: center;
  font-size: 18px;
  line-height: normal;
  text-decoration: none;
  color: #1ABC9C;
  font-weight: 600;
  background: transparent;
  transition: ease-out 0.5s;
  border: 2px solid #F7A334;
  border-radius: 5px;
  box-shadow: inset 0 -100px 0 0 #F7A334;
}

.custom_btn:hover {
  color: #1ABC9C;
  box-shadow: inset 0 0 0 0 #F7A334;
}

.custom_btn:active {
  transform: scale(0.9);
}

/* Inner Pages */
.inner_wrapper {
  background: rgb(0, 17, 173);
  background: linear-gradient(90deg,
      rgba(0, 17, 173, 1) 0%,
      rgba(0, 71, 255, 1) 100%);
  min-height: 100vh;
  margin-top: -90px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding: 150px 0 100px;
}

.inner_wrapper::before {
  content: "";
  background: url("../src/assets/images/bg_03.png") no-repeat bottom right;
  width: 928px;
  height: 759px;
  position: absolute;
  right: -130px;
  bottom: -240px;
  z-index: -1;
}

.inner_head_title {
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.8px;
  margin-bottom: 50px;
}

.dash_box_shadow {
  border-radius: 5px;
  border: 1px solid rgba(35, 178, 244, 0.25);
  background: rgba(35, 178, 244, 0.1);
  box-shadow: 0px -15px 30px 0px rgba(35, 178, 244, 0.5) inset,
    5px 6px 25px 0px rgba(35, 178, 244, 0.35);
  padding: 36px;
  height: 100%;
}

.dahboard_overall {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 60px;
}

.dahboard_overall_single {
  position: relative;
}

.dahboard_overall_single::after {
  content: "";
  background: rgba(35, 178, 244, 0.25);
  width: 1px;
  height: 75px;
  position: absolute;
  top: 8px;
  right: -60px;
}

.dahboard_overall_single:last-child::after {
  display: none;
}

.dahboard_overall_single>div {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.dahboard_overall_single>div h4 {
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.36px;
  color: #fff;
  margin-bottom: 0;
  text-transform: capitalize;
}

.dahboard_overall_single h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.4px;
  margin-bottom: 0;
}

.dash_avail_bal {
  display: flex;
  align-items: center;
}

.dash_avail_bal .dahboard_overall_single {
  width: 50%;
}

.dash_avail_bal .dahboard_overall_single::after {
  display: none;
}

.dash_avail_bal .secondary_btn {
  /* width: 50%; */
  font-size: 18px;
  font-weight: 600;
}

.chart_box {
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(36, 179, 245, 0.08);
  backdrop-filter: blur(7.5px);
  padding: 36px;
  height: 100%;
}

.dash_box_row [class*="col-"] {
  margin-bottom: 24px;
}

.inner_subtitle {
  margin-bottom: 20px;
}

.inner_subtitle h2 {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.48px;
  margin-bottom: 0;
}

.title_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.referral_overall .dahboard_overall_single::after {
  right: -150px;
}

.referral_overall .dahboard_overall_single h3 {
  font-size: 32px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.64px;
}

.chart_box .dash_box_shadow {
  padding: 20px;
  height: auto;
}

.referral_link_grp {
  position: relative;
}

.referral_link_grp input.form-control {
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.05);
  padding: 10px 40px 10px 10px;
  border: 0 !important;
  font-size: 18px;
  color: #fff;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.36px;
  pointer-events: none;
  user-select: none;
}

.referral_link_grp img {
  position: absolute;
  top: 15px;
  right: 12px;
  cursor: pointer;
}

ul.referral_share_links {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0;
  margin: 15px 0 0;
}

ul.referral_share_links li a {
  background: #fff;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: #103cca;
  font-size: 14px;
  transition: 0.2s all;
  text-decoration: none;
}

ul.referral_share_links li a .fa-whatsapp {
  font-size: 18px;
}

ul.referral_share_links li a:hover {
  background: #23b2f4;
  color: #fff;
}

.chart_box .dash_box_shadow .dahboard_overall_single::after {
  height: 70px;
  top: 2px;
  right: -100px;
}

.primary_table_div {
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(36, 179, 245, 0.08);
  backdrop-filter: blur(7.5px);
}

.primary_table {
  margin: 0;
}

.primary_table tr {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.primary_table tbody tr:last-child .primary_table tbody tr:last-child td {
  border-bottom: none !important;
}

.primary_table tbody tr {
  transition: 0.2s all;
}

.primary_table tbody tr:hover {
  box-shadow: 0px -15px 30px 0px rgba(35, 178, 244, 0.5) inset,
    5px 6px 25px 0px rgba(35, 178, 244, 0.35);
  cursor: default;
}

.primary_table thead th,
.primary_table tbody td {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.36px;
  padding: 20px;
  white-space: nowrap;
}

.wallet_bal {
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.64px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 0;
}

.wallet_bal span {
  font-size: 24px;
  font-weight: 400;
}

select.form-select.primary_inp {
  background: rgba(0, 184, 129, 0.05) url("../src/assets/images/select_dropdown.png") no-repeat right 0.75rem center !important;
  background-size: 13px 8px !important;
}

select.form-select.primary_inp option {
  background: #063cc8;
}

.primary_form span.input-group-text {
  border: 1px solid rgba(122, 183, 244, 0.25) !important;
  border-left: none !important;
  backdrop-filter: blur(2.5px);
  background: rgba(0, 184, 129, 0.05) !important;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 0 !important;
  font-size: 18px;
  color: #fff;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.36px;
}

.primary_form .input-group input {
  border-right: none !important;
}

.deposit_form {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}

.deposit_form form.primary_form {
  width: 60%;
}

.primary_form [class*="col-"] {
  margin-bottom: 0;
}

.active_chart_btngrp {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.active_chart_btngrp button {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.48px;
  border: 1px solid #23b2f4;
  background: transparent;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  flex: 1 0 0;
  justify-content: center;
  white-space: nowrap;
}

.active_chart_btngrp button:nth-child(1) {
  border-radius: 5px 0px 0px 5px;
}

.active_chart_btngrp button:nth-child(2) {
  border-radius: 0px 5px 5px 0px;
}

.active_chart_btngrp button.active {
  background: #23b2f4;
}

.earning_btngrp {
  display: flex;
  align-items: center;
  gap: 10px;
}

.chart_option_btn {
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: transparent;
  transition: 0.2s all;
  color: #fff;
  font-size: 18px;
  font-weight: 800;
  line-height: normal;
  white-space: nowrap;
}

.chart_option_btn.active,
.chart_option_btn:hover {
  border: 1px solid rgba(35, 178, 244, 0.25);
  /* background: rgba(35, 178, 244, 0.1); */
  /* background: #23b2f4; */
  background: #23b2f4;
  color: #fff;
  font-weight: 800;
  box-shadow: 0px -15px 30px 0px rgba(35, 178, 244, 0.5) inset,
    5px 6px 25px 0px rgba(35, 178, 244, 0.35);
}

.stategy_calc_value.roi_calc_value {
  max-width: 50%;
}

.stategy_calc_value.roi_calc_value>div label {
  color: #fff;
}

.stategy_calc_value.roi_calc_value>div label:nth-child(2) {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.4px;
}

.chart_box .donut_chart {
  display: flex;
  align-items: center;
  justify-content: center;
}

.invest_details_row {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.invest_details_single {
  background: url("../src/assets/images/investdetail_card_bg.png") no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 30px;
  border-radius: 5px;
  z-index: 1;
  overflow: hidden;
}

.invest_details_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.invest_details_icon {
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: rgba(255, 255, 255, 0.1);
  box-shadow: -4px 4px 11.4px 0px rgba(255, 255, 255, 0.5) inset;
  backdrop-filter: blur(5px);
  display: flex;
  padding: 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 90px;
  height: 60px;
  word-wrap: break-word;
  white-space: normal;
  font-size: 14px;
  text-align: center;
  color: #fff;
}

.invest_details_top h2 {
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.64px;
  margin-bottom: 0;
}

.invest_details_info {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  gap: 15px;
}

.invest_details_info>div {
  width: 50%;
}

.invest_details_info label {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
}

.invest_details_info h5 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.4px;
  margin-bottom: 0;
}

.invest_details_single .invest_details_info:last-child {
  margin-bottom: 0;
}

.invest_details_single button.secondary_btn {
  font-size: 18px;
  font-weight: 600;
}

.invest_details_single button.secondary_btn.disabled {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

.recent_act_firstrow {
  display: flex;
  align-items: center;
  gap: 10px;
}

.recent_act_firstrow h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.green_txt {
  color: #6aff52 !important;
}

.red_txt {
  color: #FFA3A3 !important;
}

.recent_act_secondrow h4,
.recent_act_secondrow h5 {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
  margin-bottom: 5px;
}

.recent_act_secondrow h5 {
  font-weight: 600;
}

.history_link {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
  text-decoration: none;
}

.invest_panel {
  background: url("../src/assets/images/invest_box_bg.png") no-repeat;
  background-size: cover;
  width: 100%;
  border-radius: 5px;
  padding: 30px;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.invest_panel_content {
  width: 60%;
  margin-left: auto;
  color: #fff;
}

.invest_panel_content h1 {
  font-size: 32px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.64px;
  text-align: center;
  margin: 15px 0 0;
}

.invest_panel_details {
  display: flex;
  align-items: center;
  margin-top: 50px;
}

.invest_panel_details>div {
  width: 50%;
  position: relative;
}

/* .invest_panel_details > div::after {
  content: "";
  background: rgba(35, 178, 244, 0.5);
  width: 1px;
  height: 80px;
  position: absolute;
  right: 60px;
  top: 0;
} */
.invest_panel_details>div:nth-child(2)::after {
  display: none;
}

.invest_panel_details label {
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
  margin-bottom: 10px;
}

.invest_panel_details h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.64px;
}

.invest_panel_details .secondary_btn {
  width: auto;
}

.invest_details_panel {
  align-items: center;
}


/* WHITE BOX */
.qr_box div {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
}

.qr_box {
  background: #fff;
  padding: 25px;
  height: 100%;
  border-radius: 5px;
}

/* NEWS BOX */
.news_box div {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
}

.news_box {
  background: #fff;
  padding: 25px;
  height: 100%;
  border-radius: 5px;
}

.img-container {
  max-width: 600px;
  /* Set your desired maximum width */
  width: 100%;
  height: auto;
}

.img-container img {
  width: 100%;
  height: auto;
}

.dahboard_overall_single>div i.fa-circle-info {
  color: #23b2f4;
  font-size: 16px;
}

.dash_tootip_info {
  width: 250px !important;
  background: #0047ff !important;
}

.invest_panel_wrapper {
  display: flex;
  align-items: flex-start;
}

.invest_panel_left {
  width: 35%;
}

.portfolio_table {
  background: rgb(0 17 174 / 80%);
  border: none;
  margin-bottom: 0;
  border-radius: 10px;
}

.portfolio_table th,
.portfolio_table td {
  border: none;
  background-color: transparent;
  color: #fff;
  padding-left: 20px;
}

.portfolio_table tbody th {
  color: #23b2f4;
}

.portfolio_table thead th {
  padding-top: 20px;
}

.portfolio_table tbody tr:last-child td,
.portfolio_table tbody tr:last-child th {
  padding-bottom: 20px;
}

.news_box_inner .main_title {
  font-size: 24px;
  margin-bottom: 10px;
}

.assets_allocation_section {
  padding: 80px 100px;
  border-radius: 10px;
  background: #fff;
  position: relative;
  z-index: 1;
  overflow: hidden;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.1);
}

.potfolio_detail_panel {
  padding: 30px;
  position: relative;
  background-color: #d3f1ff;
  height: 100%;
  border-radius: 10px;
}

.potfolio_detail_panel h2 {
  color: #23b2f4;
  font-size: 24px;
  font-weight: 600;
}

.potfolio_detail_panel ul {
  list-style: none;
  padding: 0;
  min-height: 420px;
}

.potfolio_detail_panel ul li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
}

.potfolio_detail_panel ul li::before {
  content: "";
  background: #23b2f4;
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 100px;
  top: 10px;
  left: 0;
}

.potfolio_detail_panel .secondary_btn:hover {
  color: #23b2f4;
}

.potfolio_detail_section [class*='col-'] {
  margin-bottom: 24px;
}

.primary_tab.nav-fill .nav-item .nav-link {
  background: #f2fbff;
  color: #555;
}

.primary_tab.nav-fill .nav-item .nav-link.active {
  background: #d3f1ff;
  color: #23b2f4;
}

.flex_btns {
  display: flex;
  gap: 10px;
}

.flex_btns_chg .secondary_btn {
  min-width: 160px;
}

.rdt_Table,
.rdt_Table>div,
.rdt_TableCol,
.rdt_TableCol_Sortable,
.rdt_TableHeadRow,
.rdt_TableRow,
.rdt_Pagination {
  background: transparent !important;
  color: #eee !important;
}

.rdt_Pagination button:disabled {
  color: rgb(255 255 255 / 18%) !important;
  fill: rgb(255 255 255 / 18%) !important;
}

.rdt_Pagination button {
  color: rgb(255 255 255 / 54%) !important;
  fill: rgb(255 255 255 / 54%) !important;
}

.current_price_div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin-bottom: 24px;
}

.current_price_div>div {
  display: flex;
  gap: 10px;
}

.current_price_div label {
  color: #555;
  font-size: 18px;
  font-weight: 600;
}

.current_price_div label:nth-child(2) {
  color: #23b2f4;
}

.current_price_div.current_price_div_dash {
  justify-content: flex-end;
}

.current_price_div.current_price_div_dash label {
  color: #d1d1d1;
}

.current_price_div.current_price_div_dash label:nth-child(2) {
  color: #23b2f4;
}

/* 

.btn_box {
  --clr-shadow__border: #d9a1ff;
  --clr-text: #F6F4EB;
  --clr-space: #23b2f4;
  --clr-space-gr: #23b2f4;
  --clr-star: #E9F8F9;
  --size: 3rem;
  position: relative; 
  transform: scale(0.7);
}

.btn_box .button {
  font-weight: 600;
  font-size: 1.5rem;
  letter-spacing: 0.2rem;
  background: transparent;
  padding: calc(var(--size) / 3) var(--size);
  border: none;
  cursor: pointer;
  color: #fff;
  border-radius: 5px;
}

.btn_box .space {
  width: 100%;
  height: 100%;
  bottom: 0%;
  gap: 1.5rem;
  transition: 0.5s ease-in-out;
  z-index: -1;
  opacity: 0;
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  display: flex;
  background: linear-gradient( 160deg, var(--clr-space), var(--clr-space-gr));
}

.btn_box .space {
  opacity: 1;
}

.btn_box .star {
  height: 4rem;
  width: 0.3rem;
  transition: 0.5s;
  border-radius: 50px;
  clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
  position: relative;
  background-color: var(--clr-star);
  animation: space-animation calc(0.1s * var(--i)) linear infinite;
}

@keyframes space-animation {
  0% {
    transform: rotate(-30deg) translateY(calc(-52% * var(--i)));
  }

  100% {
    transform: rotate(-30deg) translateY(calc(52% * var(--i)));
  }
}
 */

.button {
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  padding-block: 10px;
  padding-inline: 20px;
  background-color: rgb(35 178 244);
  border-radius: 5px;
  display: block;
  cursor: pointer;
  color: #ffff !important;
  gap: 10px;
  font-weight: bold;
  border: 0;
  outline: none;
  overflow: hidden;
  font-size: 18px;
  transform: none;
  text-decoration: none;
  text-align: center;
}

.button::before {
  animation: shine 1.5s ease-out infinite;
}

.button::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(120deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0) 70%);
  top: 0;
  left: -100px;
  opacity: 0.6;
}

@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}




.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-size: 300% 300%;
  backdrop-filter: blur(2rem);
  border-radius: 5px;
  transition: 0.5s;
  animation: gradient_301 5s ease infinite;
  border: 0;
  background-image: linear-gradient(#23b2f4, #23b0f1),
    linear-gradient(137.48deg, #23b2f4 10%, #23b2f4 45%, #23b2f4 67%, #0044ff 87%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  padding: 10px 30px;
  color: #fff !important;
  font-size: 18px;
  font-weight: 600;
  background: #23b2f4;
}

#container-stars {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: 0.5s;
  backdrop-filter: blur(1rem);
}

strong {
  z-index: 2;
  font-size: 18px;
  color: #FFFFFF;
}

#glow {
  position: absolute;
  display: flex;
  width: 12rem;
}

.circle {
  width: 100%;
  height: 30px;
  filter: blur(2rem);
  animation: pulse_3011 4s infinite;
  z-index: -1;
}

.circle:nth-of-type(1) {
  background: rgba(254, 83, 186, 0.636);
}

.circle:nth-of-type(2) {
  background: rgba(142, 81, 234, 0.704);
}



.btn:active .circle {
  background: #FE53BB;
}

#stars {
  position: relative;
  background: transparent;
  width: 200rem;
  height: 200rem;
}

#stars::after {
  content: "";
  position: absolute;
  top: -10rem;
  left: -100rem;
  width: 100%;
  height: 100%;
  animation: animStarRotate 90s linear infinite;
}

#stars::after {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
}

#stars::before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 170%;
  height: 500%;
  animation: animStar 60s linear infinite;
}

#stars::before {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
  opacity: 0.5;
}

@keyframes animStar {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-135rem);
  }
}

@keyframes animStarRotate {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0);
  }
}

@keyframes gradient_301 {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes pulse_3011 {
  0% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.dash_box_shadow.profile-view {
  height: auto;
}

.user-info {
  display: flex;
  justify-content: flex-start;
}

.set_flx {
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(36, 179, 245, 0.08);
  -webkit-backdrop-filter: blur(7.5px);
  backdrop-filter: blur(7.5px);
  padding: 0px;
}

.fp-left p {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.flex-profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 15px;
}

.flex-profile .secondary_btn {
  width: auto;
  min-width: 120px;
}

.user-info-card {
  flex-grow: 1;
  color: #fff;
  border-right: 1px solid rgba(35, 178, 244, 0.25);
  margin: 0 15px;
}

.user-info-card span {
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.36px;
  color: #fff;
  margin-bottom: 0;
  text-transform: capitalize;
}

.user-info-card p {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.4px;
  margin-bottom: 0;
}

.user-info-card:first-child {
  margin-left: 0;
}

.user-info-card:last-child {
  border-right: 0;
}

.modal-content {
  border-radius: 5px !important;
  border: 1px solid rgba(35, 178, 244, 0.25) !important;
  background: #0016b5;
  color: #ffffff;
}

.btn-close {
  box-shadow: none !important;
  filter: contrast(0) brightness(0) invert(1);
  opacity: 1 !important;
}

.modal-header {
  border-bottom: 1px solid rgba(35, 178, 244, 0.25) !important;
  ;
}

.modal-footer {
  border-top: 1px solid rgba(35, 178, 244, 0.25) !important;
}

.form-control {
  border-radius: 5px;
  border: 1px solid rgba(122, 183, 244, 0.25) !important;
  background: rgba(0, 184, 129, 0.05) !important;
  -webkit-backdrop-filter: blur(2.5px);
  backdrop-filter: blur(2.5px);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  box-shadow: none !important;
  color: #eee !important;
  height: 60px;
  padding: 12px 20px;
}

.modal-footer-flex-button {
  display: flex;
}

.modal-footer-flex-button .secondary_btn {
  margin-left: 15px;
}

.form-control::placeholder {
  color: #ffffff;
  opacity: .5;
}

.animatedButton {
  min-width: 111px;
  min-height: 49px;
}

.auth_box .form-select {
  background: #0932c2 !important;
  color: #fff !important;
  border: 1px solid rgba(122, 183, 244, 0.25) !important;
  box-shadow: none !important;
  border-radius: 5px !important;
  padding: 12px 20px !important
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.roi_calc_box .form-control {
  color: #202428 !important;
  background: #ffffff !important;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Media Queries */
@media screen and (max-width: 1399px) {
  .participant_panel {
    padding: 40px 80px;
  }

  .referral_overall .dahboard_overall_single::after {
    right: -120px;
  }

  .chart_box .dash_box_shadow .dahboard_overall_single::after {
    right: -80px;
  }

  .invest_details_single {
    padding: 20px;
  }

  .dahboard_overall_single::after {
    right: -28px;
  }

  .invest_details_top h2 {
    font-size: 28px;
  }

  /* .invest_panel_details > div::after {
    right: 35px;
  } */
  .dahboard_overall.invest_details_panel .dahboard_overall_single::after {
    right: -40px;
    height: 65px;
  }

  .potfolio_detail_panel ul {
    min-height: 460px;
  }
}

@media screen and (max-width: 1199px) {
  .page_header h5 {
    max-width: 100%;
  }

  .participant_panel {
    padding: 40px 50px;
    gap: 60px;
  }

  .participant_panel h2 {
    font-size: 80px;
  }

  .participant_panel_left div,
  .participant_panel_right div {
    margin-bottom: 10px;
  }

  .inner_bg_sec {
    padding: 30px;
  }

  .why_invest_box {
    padding: 30px;
  }

  .auth_box form.primary_form {
    max-width: 100%;
  }

  .referral_overall .dahboard_overall_single::after {
    right: -65px;
  }

  .chart_box .dash_box_shadow .dahboard_overall_single::after {
    right: -130px;
  }

  .dahboard_overall_single::after {
    right: -100px;
  }

  .invest_details_row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;
  }

  .invest_panel_content {
    width: 60%;
    text-align: center;
  }

  /* .invest_panel_details > div::after {
    right: 40px;
  } */
  .dahboard_overall.invest_details_panel {
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    gap: 30px;
  }

  .dahboard_overall.invest_details_panel .dahboard_overall_single::after {
    display: none;
  }

  .dahboard_overall_single>div {
    margin-bottom: 8px;
  }

  .potfolio_detail_panel ul {
    min-height: 550px;
  }

  .invest_panel_details {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (max-width: 991px) {
  .offcanvas {
    background: #0011ad;
  }

  .page_header {
    text-align: center;
    padding: 150px 0 50px;
  }

  .participant_panel h2 {
    font-size: 56px;
  }

  .participant_panel_left::after {
    height: 120px;
    top: 8px;
    right: -100px;
  }

  .main_title {
    text-align: center;
  }

  .news_section {
    text-align: center;
  }

  .navbar_middle {
    display: none;
  }

  .offcanvas-header .offcanvas-title {
    visibility: hidden;
  }

  .navbar_right {
    gap: 10px;
  }

  .navbar_right .nav-link {
    color: #fff;
  }

  .offcanvas-header .btn-close {
    filter: invert(1);
  }

  .navbar-toggler {
    box-shadow: none !important;
  }

  .auth_box {
    padding: 50px;
  }

  .auth_box h2 {
    text-align: center;
    font-size: 36px;
  }

  .auth_box p {
    text-align: center;
  }

  .dahboard_overall_single::after {
    display: none;
  }

  .dahboard_overall {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    text-align: center;
    row-gap: 20px;
  }

  .dahboard_overall .dahboard_overall_single>div {
    margin-bottom: 5px;
  }

  .afterlogin_dropdown_menu {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .invest_details_row {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .invest_panel::before {
    content: "";
    background: linear-gradient(90deg,
        rgb(0 17 173) 0%,
        rgb(0 71 255 / 0%) 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
    z-index: -1;
  }

  .invest_panel_content {
    max-width: 100%;
  }

  .potfolio_detail_panel ul {
    min-height: auto;
  }

  .invest_panel_wrapper {
    flex-wrap: wrap;
    gap: 15px;
  }

  .invest_panel_left {
    width: 100%;
  }

  .invest_panel_content {
    width: 100%;
  }

  .invest_panel_details>div.flex_btns {
    justify-content: center;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {

  .footer_panel_top,
  .footer_panel_bottom {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer_panel_top_left {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .custom_accordian .accordion-button {
    font-size: 16px;
  }

  .para_tag {
    font-size: 15px;
  }

  .main_title {
    font-size: 30px;
    margin-bottom: 15px;
  }

  .custom_slider .slick-prev {
    left: 0;
  }

  .custom_slider .slick-next {
    right: 0;
  }

  .slider_box {
    width: 70%;
    margin: 30px auto;
    justify-content: center;
  }

  .participant_panel_left::after {
    display: none;
  }

  .participant_panel {
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    gap: 30px;
    padding: 30px 20px;
  }

  .participant_panel h2 {
    font-size: 42px;
  }

  .page_header h1 {
    font-size: 36px;
  }

  .why_invest_box h3 {
    font-size: 20px;
  }

  .roi_calc_box {
    padding: 30px;
  }

  .auth_box h2 {
    font-size: 28px;
  }

  ul.referral_share_links {
    gap: 10px;
  }

  .primary_form [class*="col-"] {
    margin-top: 0;
  }

  .deposit_form {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }

  .deposit_form form.primary_form {
    width: 100%;
  }

  .chart_box,
  .dash_box_shadow {
    padding: 20px;
  }

  .wallet_bal {
    font-size: 24px;
  }

  .wallet_bal span {
    font-size: 18px;
  }

  .active_chart_btngrp button {
    font-size: 18px;
  }

  .recent_act_secondrow h4,
  .recent_act_secondrow h5,
  .recent_act_firstrow h4 {
    font-size: 18px;
  }

  .recent_act_firstrow h4 {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
  }

  /* .invest_panel_details > div::after {
    display: none;
  } */
  .invest_panel_details {
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 0;
    gap: 10px;
    text-align: center;
  }

  .invest_panel_details label {
    font-size: 18px;
    margin-bottom: 0;
  }

  .invest_panel_content h1 {
    font-size: 30px;
    margin: 0 0 15px;
  }

  .invest_panel_details h2 {
    font-size: 26px;
  }

  .invest_panel_details>div {
    width: 100%;
  }

  .invest_panel::before {
    opacity: 0.8;
  }

  .invest_panel_details .secondary_btn {
    width: 100%;
  }

  .invest_details_top h2 {
    font-size: 24px;
    margin-top: 10px;
  }

  .dash_avail_bal .dahboard_overall_single {
    width: 100%;
  }

  .dash_avail_bal .secondary_btn {
    width: 100%;
    margin-top: 10px;
  }

  .assets_allocation_section {
    padding: 30px 20px;
  }

  .invest_panel_details>div.flex_btns .secondary_btn {
    width: auto;
  }
}

@media screen and (max-width: 575px) {
  .roi_flex {
    flex-wrap: wrap;
    flex-direction: column;
  }

  .roi_flex .input-group,
  .roi_flex select.form-select {
    width: 100%;
  }

  .roi_flex label {
    margin-bottom: 10px;
    font-size: 16px;
  }

  .roi_calc_value {
    max-width: 100%;
  }

  .roi_calc_value>div {
    flex-wrap: wrap;
    flex-direction: column;
  }

  .roi_calc_value>div label:nth-child(2) {
    font-size: 24px;
  }

  .main_title {
    font-size: 24px;
  }

  .slider_box span {
    font-size: 20px;
  }

  .auth_box {
    padding: 30px 20px;
  }

  .auth_box h2 {
    font-size: 24px;
  }

  .d_flex {
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .invest_details_top,
  .invest_details_info,
  .dash_avail_bal {
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
  }

  .invest_details_info>div {
    width: 100%;
  }

  .dahboard_overall_single>div {
    justify-content: center;
  }

  .active_chart_btngrp {
    flex-direction: column;
    padding-bottom: 10px;
    min-width: 100%;
  }

  .active_chart_btngrp button {
    min-width: 100%;
    margin: 5px 0;
  }

  .active_chart_btngrp button:nth-child(2) {
    border-radius: 5px;
  }

  .news_wrapper {
    display: flex;
    flex-direction: column;
  }

  .current_price_div {
    flex-direction: column;
    gap: 10px;
  }

  .rdt_TableCol_Sortable>div {
    text-overflow: unset;
    overflow: initial;
    white-space: normal;
  }
}

.table_bg {
  background-color: rgba(255, 255, 255, 0.1) !important;
  --bs-table-bg: transparent;
}